<!-- eslint-disable no-unused-vars -->
<template>
<div>
    <b-tabs class="nav-tabs custom-tabs">
        <b-tab
            v-for="(tab, index) in visibleTabs"
            :key="index"
            :title="tab.title"
            :disabled="tab.disabled"
            >
            <div v-if="index === 0">
              <form @submit.prevent="agregarModificarItem">
                <div class="row mb-2">
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-3 text-center pr-0">
                        <label class="col-form-label">
                          Servicio:
                        </label>
                      </div>
                      <div class="col-sm-9 px-0">
                        <multiselect
                          v-model="serviciosSelected"
                          :options="servicios"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X">
                          <span slot="noResult">Sin Resultados</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="row">
                      <div class="col-sm-4 px-0 text-center pr-0">
                        <label class="col-form-label">
                          Categoria:
                        </label>
                      </div>
                      <div class="col-sm-8 px-1">
                        <multiselect
                          v-model="categoriaSelected"
                          :options="categoriaAseguradoras"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X">
                          <span slot="noResult">Sin Resultados</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1" v-if="serviciosSelected && textButtonForm === 'Agregar'">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="col-form-label">
                          P1: {{ serviciosSelected.precio_1 | numeral('0,0.00') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 px-0">
                    <div class="row">
                      <div class="col-sm-5 text-right pr-0">
                        <label class="col-form-label">CoaSeguro:</label>
                      </div>
                      <div class="col-sm-7 px-1">
                         <vue-numeric class="form-control"
                          v-model="coaseguro"
                          separator=","
                          :precision="2"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 px-0">
                    <div class="row">
                      <div class="col-sm-5 text-right pr-0">
                        <label class="col-form-label">Seguro:</label>
                      </div>
                      <div class="col-sm-7 px-1">
                        <vue-numeric class="form-control"
                          v-model="seguro"
                          separator=","
                          :precision="2"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 text-center">
                    <button type="submit" class="btn btn-warning">
                      {{textButtonForm}}
                    </button>
                  </div>
                </div>
              </form>
              <!-- Tabla -->
              <b-table
                class="table table-hover"
                :fields="fields"
                :items="listaTabla"
              >
                <template #cell(numero)="data">
                  {{data.index+1}}
                </template>
                <template #cell(editar)="items">
                  <i
                    @click="onClickEditarIcon(items.item)"
                    class="nav-icon text-success i-Pen-5 font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </template>
                <template #cell(eliminar)="items">
                  <i
                    @click="[onClickEliminarIcon(items), items.item.estado = false]"
                    class="nav-icon text-danger i-Close-Window font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </template>
                <template #cell(estado)="data">
                  <label class="switch switch-success mr-3">
                    <input
                        type="checkbox"
                        :checked="data.item.situacion_id === 2"
                        @change="() => modificarFilaProducto(data)"
                      >
                    <span class="slider"></span>
                  </label>
                </template>
                <template #cell(coa_seguro)="data">
                  {{data.item.coa_seguro | numeral('0,0.00')}}
                </template>
                <template #cell(seguro)="data">
                  {{data.item.seguro | numeral('0,0.00')}}
                </template>
                <template #cell(total)="data">
                  {{data.item.total | numeral('0,0.00')}}
                </template>
              </b-table>
            </div>
            <div v-if="index === 1">
              <div v-if="isArchivoActivo">
                <div class="row">
                  <div class="col-sm-1 mr-3">
                    <button
                  class="btn btn-secundary"
                  :class="{ 'active': isArchivoActivo, 'inactive': !isArchivoActivo }"
                  @click="toggleArchivoActivo"
                >
                  Regresar
                </button>
                </div>
                <div class="col-sm-3">
                  <button
                    class="btn green"
                    :disabled="isButtonDisabled"
                  >
                    Seleccionar Archivo
                  </button>
                </div>
              </div>
              <hr style="border: 1px solid black; width: 100%; margin: 20px auto;">
              <div class="row">
                <div class="col-sm-8">
                  <button
                  class="btn btn-secundary"
                >
                  Descargar plantilla
                </button>
                </div>
                <div class="col-sm-4 text-right">
                  <a>
                    Descargar Referencia <img src="../../../assets/images/e32.png" style="cursor: pointer" title="formato excel">
                  </a>
                </div>
              </div>
            </div>
              <div v-else>
              <form @submit.prevent="agregarModificarItemProducto">
                <div class="row mb-2">
                  <div class="col-sm-3">
                    <div class="row">
                      <div class="col-sm-3 text-center pr-0">
                        <label class="col-form-label">
                          Producto:
                        </label>
                      </div>
                      <div class="col-sm-9 px-0">
                        <multiselect
                          v-model="productoSelected"
                          :options="productos"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          :loading="cargarProd"
                          :internal-search="false"
                          @search-change="filtro = $event"
                        >
                          <span slot="noResult">Sin Resultados</span>
                          <template slot="afterList">
                            <div v-if="showCargarMas" class="row">
                              <a
                                href="javascript:void(0)"
                                class="col-sm-12 pl-5 py-1 text-dark font-weight-bold"
                                @click="cargarProductos"
                              >
                                Cargar más...
                              </a>
                            </div>
                          </template>
                          <template slot="option" slot-scope="props">
                            <small :class="{'disabled-option': props.option.isDisable}"
                            :style="{ pointerEvents: props.option.isDisable ? 'none' : 'auto' }"
                            :aria-disabled="props.option.isDisable"
                            >
                              {{ props.option.nombre }}
                            </small>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="row">
                      <div class="col-sm-4 px-0 text-center pr-0">
                        <label class="col-form-label">
                          Categoria:
                        </label>
                      </div>
                      <div class="col-sm-8 px-1">
                        <multiselect
                          v-model="categoriaSelectedProducto"
                          :options="categoriaAseguradoras"
                          placeholder=""
                          label="nombre"
                          track-by="id"
                          select-label=""
                          deselect-label="X">
                          <span slot="noResult">Sin Resultados</span>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-1" v-if="productoSelected && textButtonFormProd=== 'Agregar'">
                    <div class="row">
                      <div class="col-sm-12">
                        <label class="col-form-label">
                          P1: {{ productoSelected.precio_1 | numeral('0,0.00') }}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 px-0">
                    <div class="row">
                      <div class="col-sm-5 text-right pr-0">
                        <label class="col-form-label">CoaSeguro:</label>
                      </div>
                      <div class="col-sm-4 px-1">
                        <vue-numeric class="form-control"
                          v-model="coaseguroProducto"
                          separator=","
                          :precision="2"
                          :disabled="true"
                          placeholder=""
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-2 px-0">
                    <div class="row">
                      <div class="col-sm-3 text-right px-0">
                        <label class="col-form-label">Seguro:</label>
                      </div>
                      <div class="col-sm-4 px-1">
                        <vue-numeric class="form-control"
                          v-model="seguroProducto"
                          separator=","
                          :precision="2"
                          placeholder=""
                        />
                      </div>
                      <div class="col-sm-2 text-center">
                        <button type="submit" class="btn btn-warning">
                          {{textButtonFormProd}}
                        </button>
                      </div>
                    </div>
                </div>
                <div class="col-sm-2 px-2 text-center">
                        <button
                        class="btn btn-info"
                        :class="{ 'active': isArchivoActivo, 'inactive': !isArchivoActivo }"
                        @click="toggleArchivoActivo"
                      >
                        Importar
                      </button>
                      </div>
                  </div>
              </form>
              <!-- Tabla -->
              <b-table
                class="table table-hover"
                :fields="fieldsProducto"
                :items="listaTablaProductos"
              >
                <template #cell(numero)="data">
                  {{data.index+1}}
                </template>
                <template #cell(editar)="items">
                  <i
                    @click="onClickEditarIconProducto(items.item)"
                    class="nav-icon text-success i-Pen-5 font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </template>
                <template #cell(eliminar)="items">
                  <i
                    @click="[onClickEliminarIconProducto(items), items.item.estado = false]"
                    class="nav-icon text-danger i-Close-Window font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </template>
                <template #cell(estado)="data">
                  <label class="switch switch-success mr-3">
                    <input
                        type="checkbox"
                        :checked="data.item.situacion_id === 2"
                        @change="() => modificarFilaProducto(data)"
                      >
                    <span class="slider"></span>
                  </label>
                </template>
                <template #cell(coa_seguro)="data">
                  {{data.item.coa_seguro | numeral('0,0.00')}}
                </template>
                <template #cell(seguro)="data">
                  {{data.item.seguro | numeral('0,0.00')}}
                </template>
                <template #cell(total)="data">
                  {{ (Number(data.item.coa_seguro) + Number(data.item.seguro)).toFixed(2) }}
                </template>
              </b-table>
            </div>
            </div>
            <div v-if="index === 2">
              <b-table
              class="table table-hover"
              :fields="fieldsHabitacion"
              :items="datosHabitacion"
            >
            <template #cell(numero)="data">
                  {{data.index+1}}
                </template>
            <template #cell(privado)="data">
              {{ Number(data.item.privado).toFixed(2) }}
            </template>
            <template #cell(seguro)="data">
              <input
                v-model="data.item.seguro"
                @change="modificarSeguro(data, $event.target.value)"
                type="text"
              />
            </template>
            <template #cell(estado)="data">
              <label class="switch switch-success mr-3">
                <input
                  type="checkbox"
                  v-model="data.item.estado"
                  :disabled="data.item.deleted === 1"
                  :checked="data.item.situacion_id === 2"
                  @change="modificarFila(data)"
                >
                <span class="slider"></span>
              </label>
            </template>
              </b-table>
            </div>
        </b-tab>
      </b-tabs>
</div>
</template>
<script>
import store from '@/store/index';
import Multiselect from 'vue-multiselect';
import VueNumeric from 'vue-numeric';
import axios from '../../common/axiosERP';
import { util } from '../../../plugins/util';

export default {
  name: 'sectionTabServicesAndProductos',
  components: {
    Multiselect,
    VueNumeric,
  },
  props: {
    servicios: {
      type: Array,
      default: Array,
    },
    categoriaAseguradoras: {
      type: Array,
      default: Array,
    },
    situaciones: {
      type: Array,
      default: Array,
    },
    listaTablaOut: {
      type: Array,
      default: Array,
    },
    listaTablaProductosOut: {
      type: Array,
      default: Array,
    },
    datosHabitacionOut: {
      type: Array,
      default: Array,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      serviciosSelected: null,
      coaseguro: 0,
      seguro: 0,
      coaseguroProducto: 0,
      seguroProducto: 0,
      fields: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'id', label: 'ID', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'servicio', label: 'Servicio', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'categoria', label: 'Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'coa_seguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      listaTabla: [],
      buttonDisabled: false,
      categoriaSelected: null,
      categoriaSelectedProducto: null,
      modoEdicionTabla: false,
      modoEdicionTablaProducto: false,
      textButtonForm: 'Agregar',
      selectedId: null,
      selectedCategoryId: null,
      hover: false,
      productoSelected: null,
      selectedProductoId: null,
      selectedCategoryProductoId: null,
      productos: [],
      fieldsProducto: [
        {
          key: 'numero', label: 'N°', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'editar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'eliminar', label: ' ', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '3%' },
        },
        {
          key: 'codigo', label: 'Codigo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'nombre', label: 'Nombre', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'sub_grupo', label: 'Subgrupo', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'categoria_aseguradora', label: 'Categoria', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'coa_seguro', label: 'CoaSeguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'total', label: 'Total', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'th-custom-color', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      fieldsHabitacion: [
        {
          key: 'numero', label: 'N°', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'numero_sala_cama', label: 'ID', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '5%' },
        },
        {
          key: 'piso_sala', label: 'Piso/Sala', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '28%' },
        },
        {
          key: 'cama', label: 'Cama', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '19%' },
        },
        {
          key: 'clase', label: 'Clase', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'privado', label: 'Privado', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'seguro', label: 'Seguro', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '9%' },
        },
        {
          key: 'estado', label: 'Estado', thClass: 'bg-gray-300', class: 'text-center', thStyle: { width: '10%' },
        },
      ],
      listaTablaProductos: [],
      textButtonFormProd: 'Agregar',
      tabs: [
        {
          title: 'Servicio', active: true, disabled: false,
        },
        {
          title: 'Producto', active: store.getters['main/hasPermisionListarTratamiento'], disabled: false,
        },
        {
          title: 'Habitacion/Cama', active: store.getters['main/hasPermisionListarTratamiento'], disabled: false,
        },
      ],
      isArchivoActivo: false,
      isButtonDisabled: false,
      datosHabitacion: [
      ],
      page: 1,
      perPage: 100,
      totalRegistro: 0,
      cargarProd: false,
      productosFiltrados: [],
      filtro: '',
      selectedProductoTipo: null,
      delete: false,
      listaTemporal: [],
      listaTemporalServ: [],
      isTratamiento: store.getters['main/hasPermisionListarTratamiento'],
    };
  },
  created() {
    if (!this.isTratamiento) {
      this.tabs.forEach((tab) => {
        if (tab.title === 'Producto' || tab.title === 'Habitacion/Cama') {
          // eslint-disable-next-line no-param-reassign
          tab.disabled = true;
        }
      });
    }
    this.cargarProductos();
    if (this.edit === false) {
      this.cargarDatosHabitacion();
      this.listaTabla = [];
      this.listaTablaProductos = [];
    } else {
      this.listaTabla = this.listaTablaOut;
      this.listaTabla.sort((inicio, fin) => fin.id - inicio.id);
      this.listaTablaProductos = this.listaTablaProductosOut;
      this.datosHabitacion = this.datosHabitacionOut;
      this.datosHabitacion.forEach((item) => {
        if (item.situacion_id === null) {
          // eslint-disable-next-line no-param-reassign
          item.situacion_id = 1;
        }
      });
      this.datosHabitacion.forEach((item) => {
        if (item.situacion_id === 2) {
          // eslint-disable-next-line no-param-reassign
          item.estado = true;
        }
      });
      this.listaTabla = this.listaTabla
        .filter((serv) => serv.estado === 0).reverse();
      this.listaTablaProductos = this.listaTablaProductos
        .filter((producto) => producto.estado === 0).reverse();
    }
  },
  methods: {
    async cargarProductos() {
      if (this.cargarProd) return;
      this.cargarProd = true;
      const request = {
        page: this.page,
        perPage: this.perPage,
      };

      try {
        const response = await axios.get('/configuration/tipoProducto/combo', { params: request });
        const nuevosProductos = response.data.data.data.map((producto) => {
          if (this.listaTablaProductos.length === 0) {
            // Si está vacía, todos los productos están habilitados
            return {
              ...producto,
              $isDisabled: false,
            };
          }
          const estaEnListaTabla = this.listaTablaProductos.some((item) => item.tipo_producto_id === producto.id);
          return {
            ...producto,
            $isDisabled: estaEnListaTabla,
          };
        });
        this.totalRegistro = response.data.data.total;

        this.productos = [...this.productos, ...nuevosProductos];
        this.page += 1;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.cargarProd = false;
      }
    },
    async cargarDatosHabitacion() {
      try {
        const response = await axios.get('/clinic/insurer/sala_cama');
        this.datosHabitacion = response.data.data.aseguradora_sala_cama;
        this.datosHabitacion.forEach((item) => {
          if (item.situacion_id === null) {
            // eslint-disable-next-line no-param-reassign
            item.situacion_id = 1;
          }
        });
        this.datosHabitacion.forEach((item) => {
          if (item.situacion_id === 2) {
            // eslint-disable-next-line no-param-reassign
            item.estado = true;
          }
        });
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      }
    },
    actualizarFiltro() {
      const textoFiltro = this.filtro.toLowerCase();
      this.productosFiltrados = this.productos.filter((producto) => producto.nombre.toLowerCase().includes(textoFiltro));
    },
    toggleArchivoActivo() {
      this.isArchivoActivo = !this.isArchivoActivo;
    },
    agregarModificarItem() {
      if (this.situaciones === null) {
        return;
      }
      if (this.serviciosSelected === null) {
        util.showNotify('Elije un servicio', 'warn');
        return;
      }
      if (this.categoriaSelected === null) {
        util.showNotify('Elije una categoria', 'warn');
        return;
      }
      if (this.modoEdicionTabla) {
        this.modificarItem();
      } else {
        this.agregarItem();
      }
    },
    agregarItem() {
      const LISTA_VERIFICACION = this.listaTabla.filter((el) => (
        el.id === this.serviciosSelected.id
        && el.categoria_aseguradora_id === this.categoriaSelected.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const item = {
        id: 0,
        tipo_servicio_id: this.serviciosSelected.id,
        servicio: this.serviciosSelected.nombre,
        precio_1: this.serviciosSelected.precio_1,
        categoria_aseguradora_id: this.categoriaSelected.id,
        categoria: this.categoriaSelected.nombre,
        coa_seguro: this.coaseguro,
        seguro: this.seguro,
        total: this.coaseguro + this.seguro,
        situacion: this.situaciones[1].nombre,
        situacion_id: this.situaciones[1].id,
        estado: true,
        deleted: 0,
        _rowVariant: '',
      };
      this.listaTabla.unshift(item);
      this.resetearInputServicio();
      this.categoriaSelected = null;
      this.coaseguro = 0;
      this.seguro = 0;
    },
    modificarItem() {
      const LISTA_TEMP = this.listaTabla.filter((el) => (
        el.id !== this.selectedId
        || el.categoria_aseguradora_id !== this.selectedCategoryId
      ));
      const LISTA_VERIFICACION = LISTA_TEMP.filter((el) => (
        el.id === this.serviciosSelected.id
        && el.categoria_aseguradora_id === this.categoriaSelected.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const INDEX = this.listaTabla.findIndex((el) => (
        el.id === this.selectedId
        && el.categoria_aseguradora_id === this.selectedCategoryId
      ));
      this.listaTabla[INDEX].id = this.serviciosSelected.id;
      this.listaTabla[INDEX].servicio = this.serviciosSelected.nombre;
      this.listaTabla[INDEX].precio_1 = this.serviciosSelected.precio_1;
      this.listaTabla[INDEX].categoria_aseguradora_id = this.categoriaSelected.id;
      this.listaTabla[INDEX].categoria = this.categoriaSelected.nombre;
      this.listaTabla[INDEX].coa_seguro = this.coaseguro;
      this.listaTabla[INDEX].seguro = this.seguro;
      this.listaTabla[INDEX].total = this.coaseguro + this.seguro;
      this.resetearInputServicio();
      this.categoriaSelected = null;
      this.coaseguro = 0;
      this.seguro = 0;
      this.selectedId = null;
      this.selectedCategoryId = null;
      this.desactivarModoEdicion();
    },
    resetearInputServicio() {
      this.serviciosSelected = null;
    },
    activarModoEdicion() {
      this.modoEdicionTabla = true;
      this.textButtonForm = 'Modificar';
    },
    desactivarModoEdicion() {
      this.modoEdicionTabla = false;
      this.textButtonForm = 'Agregar';
    },
    onClickEditarIcon(item) {
      this.activarModoEdicion();
      this.selectedId = item.id;
      this.selectedCategoryId = item.categoria_aseguradora_id;
      this.serviciosSelected = {
        id: item.id,
        nombre: item.servicio,
        precio_1: item.precio_1,
      };
      this.categoriaSelected = {
        id: item.categoria_aseguradora_id,
        nombre: item.categoria,
      };
      this.coaseguro = item.coa_seguro;
      this.seguro = item.seguro;
    },
    onClickEliminarIcon(items) {
      this.delete = true;
      if (this.modoEdicionTabla) {
        this.selectedId = null;
        this.selectedCategoryId = null;
        this.serviciosSelected = null;
        this.categoriaSelected = null;
        this.coaseguro = 0;
        this.seguro = 0;
        this.desactivarModoEdicion();
      }
      this.listaTabla[items.index].estado = 1;
      this.listaTemporalServ = this.listaTabla;
      this.listaTabla = this.listaTabla.filter((serv) => serv.estado === 0);
    },
    modificarFila(data) {
      /* eslint-disable no-param-reassign */
      /* eslint-disable no-underscore-dangle */
      if (this.situaciones === null) {
        return;
      }
      if (data.item.estado) {
        data.item._rowVariant = '';
        data.item.situacion = this.situaciones[1].nombre;
        data.item.situacion_id = this.situaciones[1].id;
      } else {
        data.item._rowVariant = 'light';
        data.item.situacion = this.situaciones[0].nombre;
        data.item.situacion_id = this.situaciones[0].id;
      }
    },
    modificarSeguro(data, text) {
      /* eslint-disable no-param-reassign */
      /* eslint-disable no-underscore-dangle */
      if (this.seguro === null) {
        return;
      }
      if (data.item.seguro) {
        data.item._rowVariant = '';
        data.item.seguro = text;
      } else {
        data.item._rowVariant = 'light';
        data.item.seguro = text;
      }
    },
    agregarItemProducto() {
      const item = {
        tipo_producto_id: this.productoSelected.id,
        codigo: this.productoSelected.codigo,
        nombre: this.productoSelected.nombre,
        sub_grupo: this.productoSelected.sub_grupo,
        categoria_aseguradora_id: this.categoriaSelectedProducto.id,
        categoria_aseguradora: this.categoriaSelectedProducto.nombre,
        coa_seguro: this.coaseguroProducto,
        seguro: this.seguroProducto,
        situacion: this.situaciones[1].nombre,
        situacion_id: this.situaciones[1].id,
        estado: 0,
        total: this.coaseguroProducto + this.seguroProducto,
      };
      this.listaTablaProductos.unshift(item);
      this.resetearInputProductos();
      this.categoriaSelectedProducto = null;
      this.coaseguroProducto = 0;
      this.seguroProducto = 0;
    },
    activarModoEdicionProducto() {
      this.modoEdicionTablaProducto = true;
      this.textButtonFormProd = 'Modificar';
    },
    desactivarModoEdicionProducto() {
      this.modoEdicionTablaProducto = false;
      this.textButtonFormProd = 'Agregar';
    },
    agregarModificarItemProducto() {
      if (this.productoSelected === null) {
        util.showNotify('Elije un producto', 'warn');
        return;
      }
      if (this.categoriaSelectedProducto === null) {
        util.showNotify('Elije una categoria', 'warn');
        return;
      }
      if (this.modoEdicionTablaProducto) {
        this.modificarItemProducto();
      } else {
        this.agregarItemProducto();
      }
    },
    modificarFilaProducto(data) {
      /* eslint-disable no-param-reassign */
      data.item.situacion_id = data.item.situacion_id === 2 ? 1 : 2;
    },
    modificarItemProducto() {
      const LISTA_TEMP = this.listaTablaProductos.filter((el) => (
        el.id !== this.selectedProductoId
        || el.categoria_aseguradora_id !== this.categoriaSelectedProducto.id
      ));
      const LISTA_VERIFICACION = LISTA_TEMP.filter((el) => (
        el.id === this.productoSelected.id
        && el.categoria_aseguradora_id === this.categoriaSelectedProducto.id
      ));
      if (LISTA_VERIFICACION.length > 0) {
        util.showNotify('La categoria ya fue asignada, Elija otra.', 'warn');
        return;
      }
      const INDEX = this.listaTablaProductos.findIndex((el) => (
        el.id === this.selectedProductoId
        && el.categoria_aseguradora_id === this.selectedCategoryProductoId
      ));
      this.listaTablaProductos[INDEX].categoria_aseguradora = this.categoriaSelectedProducto.nombre;
      this.listaTablaProductos[INDEX].categoria_aseguradora_id = this.categoriaSelectedProducto.id;
      this.listaTablaProductos[INDEX].coa_seguro = this.coaseguroProducto;
      this.listaTablaProductos[INDEX].estado = 0;
      this.listaTablaProductos[INDEX].nombre = this.productoSelected.nombre;
      this.listaTablaProductos[INDEX].seguro = this.seguroProducto;
      this.listaTablaProductos[INDEX].situacion_id = this.situaciones[1].id;
      this.listaTablaProductos[INDEX].sub_grupo = this.productoSelected.sub_grupo;
      this.listaTablaProductos[INDEX].codigo = this.productoSelected.codigo;
      this.listaTablaProductos[INDEX].id = this.selectedProductoId;
      this.listaTablaProductos[INDEX].tipo_producto_id = this.selectedProductoTipo;
      this.listaTablaProductos[INDEX].total = this.coaseguroProducto + this.seguroProducto;
      this.resetearInputProductos();
      this.categoriaSelectedProducto = null;
      this.coaseguroProducto = 0;
      this.seguroProducto = 0;
      this.selectedProductoId = null;
      this.selectedCategoryProductoId = null;
      this.selectedProductoTipo = null;
      this.desactivarModoEdicionProducto();
    },
    onClickEditarIconProducto(item) {
      this.activarModoEdicionProducto();
      this.selectedProductoId = item.id;
      this.selectedProductoTipo = item.tipo_producto_id;
      this.selectedCategoryProductoId = item.categoria_aseguradora_id;
      this.productoSelected = this.productos.find((producto) => producto.id === item.tipo_producto_id);
      this.categoriaSelectedProducto = this.categoriaAseguradoras.find((categoria) => categoria.id === item.categoria_aseguradora_id);
      this.coaseguroProducto = item.coa_seguro;
      this.seguroProducto = item.seguro;
    },
    onClickEliminarIconProducto(items) {
      this.delete = true;
      if (this.modoEdicionTablaProducto) {
        this.categoriaSelectedProducto = null;
        this.coaseguroProducto = 0;
        this.seguroProducto = 0;
        this.selectedProductoId = null;
        this.selectedCategoryProductoId = null;
        this.desactivarModoEdicionProducto();
      }
      this.listaTablaProductos[items.index].estado = 1;
      this.listaTemporal = [...this.listaTablaProductos]; // Asegúrate de hacer una copia
      this.listaTablaProductos = this.listaTablaProductos.filter((producto) => producto.estado === 0);
    },
    openFileDialog() {
      const input = this.$refs.fileInput;
      if (input && input.click) {
        input.click();
      } else {
        console.error('Referencia a fileInput no válida');
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      console.log('Archivo seleccionado:', file ? file.name : 'Ninguno');
    },
    resetearInputProductos() {
      this.productoSelected = null;
    },
    actualizarListaTabla(nuevaLista) {
      this.listaTabla = nuevaLista;
    },
    actualizarListaTablaProductos(nuevaLista) {
      this.listaTablaProductos = nuevaLista;
    },
    actualizarDatosHabitacion(nuevaLista) {
      this.datosHabitacion = nuevaLista;
    },
    actualizarEstadoDisabledProductos() {
      this.productos.forEach((producto) => {
        const estaEnListaTabla = this.listaTablaProductos.some((item) => item.tipo_producto_id === producto.id);
        producto.$isDisabled = estaEnListaTabla;
      });
    },
  },
  computed: {
    showCargarMas() {
      return this.productos.length < this.totalRegistro;
    },
    visibleTabs() {
      return this.tabs.filter((tab) => tab.active);
    },
  /*  productosFiltro() {
      return this.productos.filter((producto) => !producto.isDisable);
    }, */
  },
  watch: {
    filtro() {
      this.actualizarFiltro();
    },
    listaTabla(newVal) {
      if (this.delete) {
        newVal = this.listaTemporalServ;
        this.delete = false;
      } else {
        this.$emit('update-lista-tabla', newVal);
      }
    },
    listaTablaProductos(newVal) {
      if (this.delete) {
        newVal = this.listaTemporal;
        this.delete = false;
      } else {
        this.$emit('update-lista-tabla-productos', newVal);
      }
      this.actualizarEstadoDisabledProductos();
    },
    datosHabitacion(newVal) {
      this.$emit('update-datos-habitacion', newVal);
    },
  },
};
</script>
<style>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
.icon-container {
  position: relative;
  display: inline-block;
}
.info-message {
  display: none;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
  background-color: rgba(36, 33, 33, 0.7);
  color: white;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  text-align: center;
  z-index: 1;
}
.visible {
  display: block;
}
.green{
  background-color: #76FF7A;
  color: white;
}
.nav-tabs .nav-item .nav-link:not(.disabled) {
  color: orange;
}
</style>
